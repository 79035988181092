import React from "react";
import MarkdownTextPage from "./MarkdownTextPage";
const markdown = `To request the deletion of all data associated with you, please email support@dinnermate.app,
and provide the following information:

Full Name
Date of Birth
`;
export default function DataDeletion() {
  return <MarkdownTextPage markdown={markdown} />;
}
