import React from "react";
import { makeStyles } from "@material-ui/core";

import ReactMarkdown from "react-markdown";
const useStyles = makeStyles(() => ({
  root: {
    color: "black",
    padding: "2rem",
    "& h1": {
      color: "black",
      fontFamily: "default",
    },
  },
}));
export default function MarkdownTextPage({ markdown }) {
  const { root } = useStyles();
  return (
    <div className={root}>
      <ReactMarkdown>{markdown}</ReactMarkdown>
    </div>
  );
}
