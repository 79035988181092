import {
  createMuiTheme,
  Grid,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import "./Landing.css";

function Background({ children }) {
  return (
    <div
      style={{ minHeight: "100vh", minWidth: "100vw" }}
      className="Background"
    >
      {children}
    </div>
  );
}
function Screenshot() {
  const classes = useStyles();
  return (
    <div
      className={`Screenshot ${classes.screenshot}`}
      style={{
        display: "inline-block",
      }}
    >
      <img
        className="Screenshot"
        alt="Dinner Mate Screenshot"
        src={`${process.env.PUBLIC_URL}/screenshot.png`}
      />
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  right: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
      justifyContent: "flex-start",
    },
  },
  left: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "right",
      justifyContent: "flex-end",
    },
  },
  header: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
      justifyContent: "flex-start",
      paddingLeft: "2rem",
    },
  },
  screenshot: {
    [theme.breakpoints.up("xs")]: {
      maxWidth: "15rem",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "10rem",
    },
  },
}));

function App() {
  const theme = createMuiTheme();

  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Background>
          <Grid container style={{ height: "100%" }}>
            <Grid
              item
              xs={12}
              justify={"flex-start"}
              alignItems={"flex-start"}
              className={classes.header}
            >
              <h1>Dinner Mate</h1>
            </Grid>
            <Grid
              item
              xs={12}
              justify="center"
              style={{
                flex: 1,
                color: "white",
                padding: "2rem",
              }}
            >
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12} md={6} justify="flex-end">
                  <Grid container justify="flex-end" spacing={3}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        justify="flex-end"
                        className={classes.left}
                      >
                        <Grid item xs={12}>
                          <div className="H2">Pick food, not fights.</div>
                        </Grid>
                        <Grid item xs={12} md={7}>
                          <div className="Subtitle">
                            Dinner Mate helps friends find a place to eat. Just
                            create a room, share your code, and start swiping!
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        justify="flex-end"
                        alignItems="center"
                        className={classes.left}
                      >
                        <Grid item>
                          <div style={{ height: "5rem" }}>
                            <a href="https://play.google.com/store/apps/details?id=com.dinnermate.swiper&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                              <img
                                style={{ objectFit: "contain", height: "100%" }}
                                alt="Get it on Google Play"
                                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                              />
                            </a>
                          </div>
                        </Grid>
                        <Grid>
                          <div style={{ height: "3.41rem" }}>
                            <a href="https://apps.apple.com/us/app/dinner-mate/id1502936116">
                              <img
                                alt="Download on the Apple App Store"
                                src={`${process.env.PUBLIC_URL}/app_store.svg`}
                                style={{ objectFit: "contain", height: "100%" }}
                              />
                            </a>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  justify="flex-start"
                  alignItems="center"
                  className={classes.right}
                >
                  <Screenshot />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Background>
      </div>
    </ThemeProvider>
  );
}

export default App;
