import React from "react";

import { BrowserRouter, Switch, Route } from "react-router-dom";

import Landing from "./Landing";
import Privacy from "./Privacy";
import TOC from "./TOC";
import DataDeletion from "./DataDeletion";

export default function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Landing} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/terms" component={TOC} />
        <Route path="/deletion" component={DataDeletion} />
      </Switch>
    </BrowserRouter>
  );
}
